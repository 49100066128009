
//
// home
//


.grid-menu_item{
  margin-bottom: 30px;
  height: 250px;
  color: white;
  @extend %montserrat-uppercase;
  text-align: center;
  font-size: 1.4rem;
  background-size: cover;
  position: relative;
  z-index: 10;
  &:before{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    z-index: 11;
    transition: background-color 0.2s ease;
  }
  &:hover{
    cursor: pointer;
    box-shadow:0 0 15px 1px rgba(0,0,0,0.4);
    &:before{
      background: rgba(2,117,216,0.8);
    }
  }
  &:focus, &:active{
    outline: none !important;
  }
  small{
    font-weight: $font-bold;
  }
  p{
    width: 100%;
    position: relative;
    z-index: 12
  }
  i{
    font-size: 3rem
  }
}

#nuova-struttura-link{
  background: url('/assets/css-images/nuova-struttura.jpg')no-repeat center center;
}

#modifica-struttura-link{
  background: url('/assets/css-images/modifica-struttura.jpg')no-repeat center center;
}

#modifica-impresa-link{
  background: url('/assets/css-images/modifica-impresa.jpg')no-repeat center center;
}

#ricerca-link{
  background: url('/assets/css-images/ricerca.jpg')no-repeat center center;
}



@media (min-width: 992px) {
  &#nuova-struttura-link{
    height: 450px;
    font-size: 2rem
  }
  &#ricerca-link{
    height: 170px
  }
}


#widget{
  background: $light-gray;
}

#dati-riepilogo{
  background: white;
  font-family: $font-display;
  h3{
    font-weight: $font-regular
  }
  i{
    font-size: 3rem;
    height: 55px;
    width: 100px;
    text-align: center;
  }
  strong{
    font-size: 2rem;
  }
  span{
    font-size: 1.3rem;
    display: block;
    line-height: 1rem
  }
  .row>riepilogo{
    border-bottom: 1px solid #cccccc
  }
}

.btn-login{
  cursor: pointer;
}

.progressbar{
  background: #D8D8D8;
  width: 200px;
  height: 8px;
  border-radius: 100px;
  position: relative;
  margin-top: 7px;
  .bar{
    background: #7ED321;
    position: absolute;
    top: 0;
    left: 0;
    height: 8px;
    border-radius: 100px;
    &._20{
      width: 20%;
    }
    &._40{
      width: 40%;
    }
    &._60{
      width: 60%;
    }
    &._80{
      width: 80%;
    }
    &._100{
      width: 100%;
    }
  }
}
