
//
// ricerca
//

#search-area{
  background: $light-gray;
}

.search-container{
  position: relative;
  z-index: 10;
  input[type="search"]{
    position: relative;
    padding-left: 50px;
    z-index: 11;
  }
  &:before{
    content: "\f002";
    display: block;
    z-index: 12;
    position: absolute;
    top: 25%;
    left: 0;
    width: 50px;
    height: 50%;
    color: #999999;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
  }
}

#filter-column{
  .card-header{
    h5{
      text-transform: capitalize;
    }
    &:hover{
      cursor: pointer;
    }
  }
  .card-body{
    font-size: 0.85rem;
    max-height: 200px;
    overflow: auto;
  }
  li{
    border-radius: 4px;
    .badge{
      background: $sardinia-light-blue;
      border-radius: 4;
    }
    &.active{
      font-weight: $font-bold;
      .badge{
        background: $yellow;
      }
    }
  }
}

#search-results{
  .lista--risultati{
    li{
      .actions{
        color: #999999;
        display: none;
        button{
          font-size: 0.6rem
        }
      }
      &:hover{
        div{
          cursor: default;
        }
        .actions{
          display: block;
        }
      }
    }
  }
}

.map-container{
  display: block;
  min-height: 350px;
  max-height: 250px;
  width: 100%;
  height: 100%;
  #leafletmap{
  width: 100%;
  height: 100%;
  }
}

.results-scroller{
  display: block;
  min-height: 350px;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.half-results-scroller{
  display: block;
  min-height: 450px;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .results-scroller{
    max-height: calc(100vh) ;
  }
  .map-container{
    max-height: calc((100vh - 50px)/2 );
    max-width: 576px;
  }
  .half-results-scroller {
    max-height: calc((100vh);
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .results-scroller{
    max-height: calc(100vh ) ;
  }
  .map-container{
    max-height: calc((100vh));
    max-width: 768px;
  }
  .half-results-scroller {
    max-height: calc((100vh - 150px)/2 );
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .results-scroller{
    max-height: calc(100vh) ;
  }
  .map-container{
    max-height: calc(100vh );
    max-width:  992px;
  }
  .half-results-scroller {
    max-height: calc((100vh - 150px)/2 );
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .results-scroller {
    max-height: calc(100vh - 170px) ;
  }
  .map-container {
    max-height: calc((100vh - 170px)/2 - 150px );
  }
  .half-results-scroller {
    max-height: calc((100vh - 170px)/2 );
  }
}

.dropdown-menu{
  width: 100%
}