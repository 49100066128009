
//
// navbar
//

#brand a{
  display: inline-block;
}

#brand img{
  display: block;
  max-width: 100%
}

.navbar{
  background: $sardinia-blue;
  padding: 0;
  li.nav-item{
    &.active{
      background: $yellow;
      a.nav-link{
        color:rgba(255,255,255,1);
      }
    }
    &:hover{
      a.nav-link{
        color:rgba(255,255,255,1);
      }
    }
    a.nav-link{
     @extend %montserrat-uppercase;
     font-size: 0.7rem;
     color:rgba(255,255,255,0.7);
     padding:15px !important;
   }
   &:last-child{
     border-left:1px solid $blue;
   }
  }
  .navbar-toggler{
    margin: 10px;
    border-color: rgba(255,255,255,1);
    span{
      width:20px;
      height: 20px;
      color: white;
    }
  }
  .badge{
    top: -6px;
    margin-left: 2px;
    background: $orange;
  }
}



.user-area{
  padding: 3px 10px;
  background: $sardinia-dark-blue;
  color:white;
  font-size: 0.8rem;
  font-family: $font-display;
  .fas{
    font-size: 1.9rem;
  }
  a{
    @extend %montserrat-uppercase;
    color:white;
    font-size: 0.7rem;
  }
  &.active{
    background: $yellow;
  }
}
