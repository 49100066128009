//
// liste
//

.lista_custom{
  list-style-type: none;
  font-size: 0.8rem;
  li{
    border-bottom:1px solid #cccccc;
    transition: background-color 0.2s ease;
    line-height: 1rem;
    // &:first-child{
    //   border-top: 1px solid #cccccc
    //   }
    &:hover{
      cursor: pointer;
      >div{
        background: white;
        border-radius: 4px;
      }
    }
  }
  &.gray-theme li:hover>div{
    background: #f4f4f4
  }
}

.lista--pratiche{
  .data{
    color: $medium-gray;
  }
}

.lista--full-page{
  h4{
    font-size: 1.15rem;
  }
  p{
    font-size: 1rem;
  }
}

//
// step
//


//
// card
//

.card{
  &.bg-light{
    background: $light-gray !important;
  }
  .card-header{
    background: none !important
  }
}


.sticky-sidebar {
    position: sticky;
    position: -webkit-sticky;
    top: 10px;
}

.modal-body {
  max-height: 70vh;
  overflow: auto;
  .card-body{
    font-size: 0.8rem;
  }
}

.modal{
  display:block
}
.modal-backdrop
{
    opacity:0.5 !important;
}