/* work-sans-regular - latin */
@font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    src: url('work-sans-v3-latin/work-sans-v3-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Work Sans'), local('WorkSans-Regular'),
         url('work-sans-v3-latin/work-sans-v3-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('work-sans-v3-latin/work-sans-v3-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('work-sans-v3-latin/work-sans-v3-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('work-sans-v3-latin/work-sans-v3-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('work-sans-v3-latin/work-sans-v3-latin-regular.svg#WorkSans') format('svg'); /* Legacy iOS */
  }
  /* work-sans-700 - latin */
  @font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 700;
    src: url('work-sans-v3-latin/work-sans-v3-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Work Sans Bold'), local('WorkSans-Bold'),
         url('work-sans-v3-latin/work-sans-v3-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('work-sans-v3-latin/work-sans-v3-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('work-sans-v3-latin/work-sans-v3-latin-700.woff') format('woff'), /* Modern Browsers */
         url('work-sans-v3-latin/work-sans-v3-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('work-sans-v3-latin/work-sans-v3-latin-700.svg#WorkSans') format('svg'); /* Legacy iOS */
  }
  /* work-sans-800 - latin */
  @font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 800;
    src: url('work-sans-v3-latin/work-sans-v3-latin-800.eot'); /* IE9 Compat Modes */
    src: local('Work Sans ExtraBold'), local('WorkSans-ExtraBold'),
         url('work-sans-v3-latin/work-sans-v3-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('work-sans-v3-latin/work-sans-v3-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
         url('work-sans-v3-latin/work-sans-v3-latin-800.woff') format('woff'), /* Modern Browsers */
         url('work-sans-v3-latin/work-sans-v3-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
         url('work-sans-v3-latin/work-sans-v3-latin-800.svg#WorkSans') format('svg'); /* Legacy iOS */
  }

  /* montserrat-200 - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 200;
    src: url('montserrat-v12-latin/montserrat-v12-latin-200.eot'); /* IE9 Compat Modes */
    src: local('Montserrat ExtraLight'), local('Montserrat-ExtraLight'),
         url('montserrat-v12-latin/montserrat-v12-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('montserrat-v12-latin/montserrat-v12-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
         url('montserrat-v12-latin/montserrat-v12-latin-200.woff') format('woff'), /* Modern Browsers */
         url('montserrat-v12-latin/montserrat-v12-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
         url('montserrat-v12-latin/montserrat-v12-latin-200.svg#Montserrat') format('svg'); /* Legacy iOS */
  }
  /* montserrat-regular - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url('montserrat-v12-latin/montserrat-v12-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Montserrat Regular'), local('Montserrat-Regular'),
         url('montserrat-v12-latin/montserrat-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('montserrat-v12-latin/montserrat-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('montserrat-v12-latin/montserrat-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('montserrat-v12-latin/montserrat-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('montserrat-v12-latin/montserrat-v12-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
  }
  /* montserrat-italic - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 400;
    src: url('montserrat-v12-latin/montserrat-v12-latin-italic.eot'); /* IE9 Compat Modes */
    src: local('Montserrat Italic'), local('Montserrat-Italic'),
         url('montserrat-v12-latin/montserrat-v12-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('montserrat-v12-latin/montserrat-v12-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('montserrat-v12-latin/montserrat-v12-latin-italic.woff') format('woff'), /* Modern Browsers */
         url('montserrat-v12-latin/montserrat-v12-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('montserrat-v12-latin/montserrat-v12-latin-italic.svg#Montserrat') format('svg'); /* Legacy iOS */
  }
  /* montserrat-800 - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    src: url('montserrat-v12-latin/montserrat-v12-latin-800.eot'); /* IE9 Compat Modes */
    src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'),
         url('montserrat-v12-latin/montserrat-v12-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('montserrat-v12-latin/montserrat-v12-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
         url('montserrat-v12-latin/montserrat-v12-latin-800.woff') format('woff'), /* Modern Browsers */
         url('montserrat-v12-latin/montserrat-v12-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
         url('montserrat-v12-latin/montserrat-v12-latin-800.svg#Montserrat') format('svg'); /* Legacy iOS */
  }