$font-display: 'Montserrat', sans-serif;
$font-body: 'Work Sans', sans-serif;
$font-light: 200;
$font-regular: 400;
$font-italic: italic;
$font-semibold: 600;
$font-bold: 800;


%montserrat-uppercase{
  font-family: $font-display;
  text-transform: uppercase;
  font-weight: $font-bold;
}

$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`

$h1-font-size:                $font-size-base * 2 !default;
$h2-font-size:                $font-size-base * 1.5 !default;
$h3-font-size:                $font-size-base * 1.5 !default;
$h4-font-size:                $font-size-base * 1 !default;
$h5-font-size:                $font-size-base * 1 !default;
$h6-font-size:                $font-size-base !default;


$headings-font-weight:        800 !default;


//
// Color system
//

$light-gray: #f4f4f4 !default;
$medium-gray: #808080 !default;
$gray-800: #343a40 !default;
$blue:    #0275D8 !default;
$orange:  #D44D13 !default;
$yellow:  #F3A201 !default;
$green:   #7ED321 !default;
// additional colors for anagrafica-strutture
$sardinia-blue:      #174B7D !default;
$sardinia-dark-blue: #10293E !default;
$sardinia-light-blue:#B1D3EF !default;
$sardinia-red: #ff4136 !default;
//
