//
// Modifica struttura
//

#step{
  font-family: $font-display;
  position: relative;
  z-index: 10;
  &:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    margin-top:15px;
    width: 100%;
    border-top:1px solid #cccccc;
    z-index: 11
  }
  .step{
    position: relative;
    &:first-child:before, &:last-child:before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 50%;
      background: white;
      z-index: 12;
    }
    &:last-child:before{
      left: auto;
      right: 0;
    }
    .number{
      position: relative;
      z-index: 13;
      display: inline-block;
      width: 30px;
      height: 30px;
      line-height: 30px;
      border-radius: 100px;
      color: white;
      text-align: center;
      font-weight: $font-bold;
      background: $sardinia-light-blue;
      transition: background-color 0.2s ease;
    }
    p{
      position: relative;
      z-index:13;
    }
    &.done{
      .number{
        background: $sardinia-blue
      }
    }
    &.active{
      font-weight: $font-bold;
      .number{
        background: $yellow !important
      }
    }
    &:hover{
      .number{
        background: $sardinia-blue
      }
    }
    &.error{
      .number{
        background: $sardinia-red
      }
    }
  }
}


#info-stato{
  font-size: 0.75rem;
  .btn{
    font-size:0.7rem
  }
}

.form_section{
  background: $yellow;
  @extend %montserrat-uppercase;
  color: white;
}

#form-modules{
  .card.active{
    border:2px solid $yellow;
  }
  .card-body{
    font-size: 0.85rem;
  }
  .lista--form{
    position: relative;
    z-index: 10;
    &:before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 1px;
      border-left: 1px solid #cccccc;
      z-index: 11;
    }
  }
  li{
    border-radius: 4px;
    position: relative;
    z-index: 12;
  &:first-child:before, &:last-child:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    margin-left: -7px;
    width: 14px;
    height: 14px;
    background: $light-gray;
    z-index: 13;
  }
  &:last-child:before{
    top: auto;
    bottom: 0;
  }
    &:after{
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      height: 14px;
      width: 14px;
      margin-top: -7px;
      margin-left: -7px;
      background: $sardinia-light-blue;
      border-radius: 100px;
      z-index: 14;
    }
    &.active{
      font-weight: $font-bold;
      &:after{
        background: $yellow;
      }
    }
    &.done{
      &:after{
        background: $sardinia-blue;
      }
    }
    &.error{
      &:after{
        background: $sardinia-red;
      }
    }
  }
}

#form hr{
  display: block;
  clear: both;
  width: 100%;
  margin: 10px 15px;
  box-sizing: border-box;
  border: none;
  height: 1px;
  background: #dedede;
}
