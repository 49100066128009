
body{
  font-family: $font-body;
  font-size: $font-size-base;
  color: $gray-800;
}

//
// Headings
//

h1, h2, h3,
.h1, .h2, .h3{
  font-family: $font-display !important;
  font-weight: $font-bold;
  color: $gray-800;
}

h4,.h4{
  font-weight: $font-bold;
  color: $gray-800;
}

 h5, h6,
.h5, .h6 {
  font-family: $font-display !important;
  font-weight: $font-regular !important;
}

h1, .h1 { font-size: $h1-font-size; }
h2, .h2 { font-size: $h2-font-size; }
h3, .h3 { font-size: $h3-font-size; }
h4, .h4 { font-size: $h4-font-size; }
h5, .h5 { font-size: $h5-font-size; }
h6, .h6 { font-size: $h6-font-size; }

.underlined{
  display: inline-block;
  border-bottom: 4px solid $gray-800;
  padding-bottom: 5px
}

.btn{
  @extend %montserrat-uppercase;
  font-size: 0.8rem;
}

.btn-outline-primary{
  border-width: 2px;
  &:hover,&:active, &:focus{
    color: #0062cc !important;
    border-color: #0062cc !important;
    background: white !important;
  }
}

.btn-link.disabled, .btn-link:disabled {
    color: $blue !important;
    opacity: 0.5;
}

input::placeholder, .placeholder {
  color: #999999;
}

.badge{
  position: relative;
  font-size: 0.75rem;
}
