//
// Form e input
//
input[type="radio"]+label{
  font-size: 0.85rem;
  padding-top: 2px
}

.lista-allegati{
  ul{
    list-style-type: none;
    font-size: 0.85rem;
  }
  li{
    border-radius: 4px;
    &:nth-child(odd){
      background: $light-gray;
    }
    .actions{
      color: #999999;
      .btn{
       font-size: 0.6rem
     }
    }
  }
}


.search-results--predictive{
  ul{
    max-height: 200px;
    overflow: auto;
  }
  p{
    border-top:1px solid #cccccc;
  }
}
