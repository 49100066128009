//
// facets
//

.facet {
  .card-body {
    &.list-preview {
      overflow-y: hidden!important;
    }
  }
}
