//
// footer
//

footer{
  font-size: 0.75rem;
}

#footer-brand{
  border-top: 4px solid $gray-800;
  a{
    display: inline-block;
  }
  img{
    max-height: 50px;
  }
}
